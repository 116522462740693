<template>
  <div class="CreateOrgSimplified">
    <div
      v-if="!user.loggedIn"
      :class="[
        'authPrompt',
        'row',
        'alignCentreRow',
        { extraClass: !user.loggedIn },
      ]"
    >
      <button @click="toggleLoginModal" class="button">Log in</button>
      <span>or</span>
      <button @click="toggleRegisterModal" class="button">Register</button>
      <span>to create your unit</span>
    </div>

    <div v-else>
      <div class="row alignCentreRow">
        <ToggleSwitch
          label="Open economy"
          :justifyToStart="true"
          v-model="allowTradesState"
          hideStatusText
        />
        <Tooltip
          :message="'Toggle open ecnomy ON to view other units and trade with them.'"
        />
      </div>

      <!-- Add a button to trigger the organisation creation -->
      <button v-if="isCreating" class="button" disabled>Processing...</button>
      <button v-else @click="createOrganisation" class="button">
        Create unit
      </button>

      <!-- Loading screen/modal that shows while organisation is being created -->
      <!-- <div v-if="isCreating" class="overlay">
        <div class="modal-content">
          <button class="button" disabled>Processing...</button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import { createCustomOrganisation } from "@/utils/createCustomOrganisation";
import { useRouter } from "vue-router";
import ToggleSwitch from "@/components/GenericComponents/ToggleSwitch.vue";
import Tooltip from "@/components/GenericComponents/Tooltip.vue";

export default {
  name: "CreateOrgSimplified",
  components: {
    ToggleSwitch,
    Tooltip,
  },
  props: {
    coinName: { type: String, default: "Unit" },
    coinAmount: { type: Number, default: 1 },
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.state.user);
    const isCreating = ref(false); // Add a reactive variable to track the creating state
    const allowTradesState = ref(true);
    const router = useRouter(); // Initialize router for page navigation

    const toggleLoginModal = () => store.dispatch("toggleLoginModal");
    const toggleRegisterModal = () => store.dispatch("toggleRegisterModal");

    const coinSymbolMap = {
      A: "₳",
      B: "฿",
      C: "Ċ",
      D: "Đ",
      E: "Ē",
      F: "₣",
      G: "₲",
      H: "Ħ",
      I: "Ī",
      J: "Ĵ",
      K: "₭",
      L: "Ł",
      M: "₼",
      N: "₦",
      O: "Ɵ",
      P: "₱",
      Q: "Ƣ",
      R: "Ɍ",
      S: "Ŝ",
      T: "₸",
      U: "Ʉ",
      V: "∀",
      W: "₩",
      X: "Ẋ",
      Y: "¥",
      Z: "Ƶ",
    };
    const coinSymbol = computed(() => {
      const firstLetter = props.coinName.charAt(0).toUpperCase();
      return coinSymbolMap[firstLetter] || "Ʉ";
    });

    const createOrganisation = async () => {
      if (!props.coinName.trim()) {
        toast.error("Coin name is required.", { autoClose: 5000 });
        return;
      }

      isCreating.value = true;
      console.log("Starting the organisation creation process...");

      const orgDetails = {
        organisationName: `${props.coinName.trim()} unit`,
        description: "No description",
        category: "Other/Specialised",
        basicIncome: 0,
        regularity: 30,
        nextTriggerTime: "",
        coinName: props.coinName.trim(),
        coinSymbol: coinSymbol.value, // Use the computed coin symbol
        allowTrades: allowTradesState.value,
        baseFounderCompensation: 1,
        allowSavings: false,
        infiniteSavings: true,
        currentAccountCap: props.coinAmount * 12,
        currentAccountLifespan: 365,
        averageSavingsAccountCap: props.coinAmount * 60,
        initialCoins: props.coinAmount,
        loans: {
          dti_effortRate: 0,
          maxLoanTerm: 0,
          annualInterest: 0,
          allowIndividual: false,
          allowJoint: false,
        },
      };

      const regularityDays = parseInt(orgDetails.regularity, 10);
      const nextTriggerTime = new Date(
        Date.now() + regularityDays * 24 * 60 * 60 * 1000
      );
      orgDetails.nextTriggerTime = nextTriggerTime;

      try {
        const orgUID = await createCustomOrganisation(store, orgDetails);
        console.log("Organisation created successfully with UID:", orgUID);

        // Dispatch coin creation action
        await store.dispatch("coinCreationActions/createCoin", {
          orgUID,
          currentAccountLifespan: 365,
          coinAmount: props.coinAmount,
        });

        // Success message
        toast.success("Organization and coin created successfully!", {
          autoClose: 5000,
        });

        // After the process completes, refresh the page
        setTimeout(() => {
          window.location.reload();
        }, 50); // Add a small delay before refresh
      } catch (error) {
        toast.error(
          "Error during the organization or coin creation process: " + error,
          {
            autoClose: 5000,
          }
        );
        console.error(
          "Error during the organization or coin creation process:",
          error
        );
      } finally {
        isCreating.value = false;
        console.log("Organisation creation process completed.");
      }
    };

    watch(coinSymbol, (newSymbol) => {
      store.dispatch("updateCoinSymbol", {
        symbol: newSymbol || "Ʉ",
        name: props.coinName || "Unit",
      });
      store.commit("SET_MONETARY_PARAMS", {
        coinName: props.coinName,
        coinSymbol: newSymbol,
      });
    });

    return {
      user,
      toggleLoginModal,
      toggleRegisterModal,
      createOrganisation,
      isCreating,
      coinSymbol,
      allowTradesState,
    };
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.extraClass {
  justify-content: center;
}
</style>
